import {
  Divider,
  Stack,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  MenuItem,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { LoadingButton } from '@mui/lab';

import { Strings } from '@app/constants';
import { Header } from '@app/sections/header';
import { useRequestState } from '@app/hooks';
import { FormProvider, RHFTextField } from '@app/components';
import { IAddCollectionSchema, ICollection } from '../types';
import { addCollectionSchema } from '../validator';
import { addCollection } from '../slice';
import { selectAddCollectionState } from '../selectors';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  row?: ICollection;
}

const AddCollection = ({ open, onClose, row }: Props) => {
  const dispatch = useDispatch();

  const methods = useForm<IAddCollectionSchema>({
    resolver: yupResolver(addCollectionSchema) as any,
    defaultValues: {
      name: get(row, 'name', ''),
      active: get(row, 'active', ''),
      description: get(row, 'description', ''),
    },
  });

  const onSubmit = (form: IAddCollectionSchema) => {
    dispatch(addCollection(form));
  };

  const { loading } = useRequestState({
    stateSelector: selectAddCollectionState,
    onSuccess: onClose,
    successMessageShown: true,
    errorShown: true,
  });
  return (
    <>
      <FormProvider methods={methods}>
        <Dialog open={open} fullWidth>
          <Header variant="dialog" title={Strings.button.addCollection} onClose={onClose} />

          <Divider />

          <DialogContent>
            <Stack spacing={2} my={2}>
              <RHFTextField
                name="name"
                required
                label={Strings.field.name}
                fullWidth
                variant="outlined"
              />

              <RHFTextField
                name="active"
                required
                label={Strings.field.type}
                variant="outlined"
                select
              >
                <MenuItem value={'true'}>True</MenuItem>
                <MenuItem value={'false'}>False</MenuItem>
              </RHFTextField>
              <RHFTextField
                name="description"
                required
                label={Strings.field.description}
                fullWidth
                variant="outlined"
                multiline
                minRows={2}
              />
            </Stack>
          </DialogContent>

          <Divider />

          <DialogActions>
            <Button variant="outlined" color="inherit" onClick={onClose}>
              {Strings.button.cancel}
            </Button>

            <LoadingButton
              loading={loading}
              type="submit"
              variant="contained"
              onClick={methods.handleSubmit(onSubmit)}
            >
              {Strings.button.save}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
};

export default AddCollection;
