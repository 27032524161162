import {
  Divider,
  Stack,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  Checkbox,
  Typography,
  useTheme,
  IconButton,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { get } from 'lodash';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';

import { Strings } from '@app/constants';
import { Header } from '@app/sections/header';
import { useRequestState } from '@app/hooks';
import { FormProvider, Iconify, RHFTextField } from '@app/components';
import { IAddAttributeSchema, IAttribute } from '../types';
import { addAttributeSchema } from '../validator';
import { addAttribute } from '../slice';
import { selectAddAttributeState } from '../selectors';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  row?: IAttribute;
}

const AddAttribute = ({ open, onClose, row }: Props) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const methods = useForm<IAddAttributeSchema>({
    resolver: yupResolver(addAttributeSchema) as any,
    defaultValues: {
      name: get(row, 'name', ''),
      isPrefix: get(row, 'isPrefix', false),
      showAsFilter: get(row, 'showAsFilter', false),
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      attributes: row?.isPrefix || row?.showAsFilter ? row?.prefixes : [],
    },
  });

  const onSubmit = (form: IAddAttributeSchema) => {
    dispatch(addAttribute({ id: row?.id, ...form }));
  };

  const handleAddMore = () => {
    const attributes = methods.getValues('attributes');
    methods.setValue('attributes', [...attributes, { id: null, prefix: '' }]);
  };

  const { loading } = useRequestState({
    stateSelector: selectAddAttributeState,
    onSuccess: onClose,
    successMessageShown: true,
    errorShown: true,
  });

  const options = [
    { id: 'isPrefix', label: 'Is Prefix' },
    { id: 'showAsFilter', label: 'Show As Filter' },
  ];

  const isPrefix = methods.watch('isPrefix');
  const showAsFilter = methods.watch('showAsFilter');
  const attributeValues = methods.watch('attributes');

  return (
    <>
      <FormProvider methods={methods}>
        <Dialog open={open} fullWidth>
          <Header
            variant="dialog"
            title={row?.id ? Strings.pageTitle.editAttribute : Strings.button.addAttribute}
            onClose={onClose}
          />

          <Divider />

          <DialogContent>
            <Stack spacing={2} my={2}>
              <RHFTextField
                name="name"
                required
                label={Strings.field.name}
                fullWidth
                variant="outlined"
              />
              <Stack sx={{ flexDirection: { sx: 'column', sm: 'row', md: 'row' } }}>
                {options.map((item: any) => {
                  return (
                    <Stack key={item.id} direction={'row'} sx={{ alignItems: 'center' }}>
                      <Checkbox
                        title={item.label}
                        checked={methods.watch(item.id)}
                        name={item.id}
                        key={item.id}
                        onChange={(e) => {
                          const event = e.target.name;
                          if (event === 'isPrefix') {
                            methods.setValue('isPrefix', !isPrefix);
                          } else {
                            methods.setValue('showAsFilter', !showAsFilter);
                          }
                          const updatedIsPrefix = methods.watch('isPrefix');
                          const updatedShowAsFilter = methods.watch('showAsFilter');
                          if (
                            (updatedIsPrefix || updatedShowAsFilter) &&
                            attributeValues.length === 0
                          ) {
                            methods.setValue('attributes', [{ id: null, prefix: '' }]);
                          } else if (!updatedIsPrefix && !updatedShowAsFilter) {
                            methods.setValue('attributes', []);
                          }
                        }}
                      />
                      <Typography>{item.label}</Typography>
                    </Stack>
                  );
                })}
              </Stack>
              {(isPrefix || showAsFilter) && (
                <Stack spacing={2} my={2}>
                  {attributeValues.map((_: any, index: number) => {
                    return (
                      <Stack
                        key={index}
                        sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}
                      >
                        <RHFTextField
                          key={index}
                          name={`attributes.${index}.prefix`}
                          placeholder={`Value ${index + 1}`}
                          required
                        />
                        <Stack>
                          <IconButton
                            disabled={attributeValues.length === 1}
                            sx={{
                              backgroundColor:
                                attributeValues.length === 1
                                  ? theme.palette.action.disabledBackground
                                  : theme.palette.primary.main,
                              borderRadius: '7px',
                              '&:hover': {
                                backgroundColor: theme.palette.primary.dark,
                              },
                              '&.Mui-disabled': {
                                backgroundColor: theme.palette.action.disabledBackground,
                                color: theme.palette.action.disabled,
                              },
                            }}
                            onClick={() => {
                              const newAttributes = attributeValues?.filter(
                                (_: any, attrIndex: number) => attrIndex !== index
                              );
                              methods.setValue('attributes', newAttributes);
                            }}
                          >
                            <Iconify icon="mdi:minus" sx={{ color: theme.palette.common.white }} />
                          </IconButton>
                        </Stack>
                      </Stack>
                    );
                  })}
                </Stack>
              )}
              {(isPrefix || showAsFilter) && (
                <Button
                  variant="outlined"
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    py: 1.5,
                    alignItems: 'center',
                    color: theme.palette.grey[500],
                    border: `1px dashed ${theme.palette.grey[500]}`,
                    '&:hover': {
                      color: theme.palette.grey[500],
                      backgroundColor: theme.palette.common.white,
                      border: `1px dashed ${theme.palette.grey[500]}`,
                    },
                  }}
                  onClick={handleAddMore}
                >
                  <Iconify icon="mdi:plus" />
                  <Typography>{Strings.button.addMore}</Typography>
                </Button>
              )}
            </Stack>
          </DialogContent>

          <Divider />

          <DialogActions>
            <Button variant="outlined" color="inherit" onClick={onClose}>
              {Strings.button.cancel}
            </Button>

            <LoadingButton
              loading={loading}
              type="submit"
              variant="contained"
              onClick={methods.handleSubmit(onSubmit)}
            >
              {Strings.button.save}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
};

export default AddAttribute;
