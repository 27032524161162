/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, DialogActions } from '@mui/material';

import { Header } from '@app/sections/header';
import { Strings } from '@app/constants';
import { useTable } from '@app/components/table';
import AttributeList from './attribute-list';
import { useDialog, useRequestState } from '@app/hooks';
import AddDialog from './add-attribute';
import { selectAttributesState } from '../selectors';
import { getAttributes } from '../slice';
import { IAttribute } from '../types';
import { usePageCount } from '@app/hooks/use-page-count';

const TABLE_HEAD_CSV = [
  { key: 'id', label: 'id', align: 'left' },
  { key: 'name', label: 'Name', align: 'left' },
  { key: 'status', label: 'Status', align: 'left', sortable: false },
];
interface Props {
  onClose?: VoidFunction;
  onSubmit?: (selected: IAttribute[]) => void;
  selectedAttributes?: any;
}

const AttributeListPage = ({ onSubmit, onClose, selectedAttributes }: Props) => {
  const dispatch = useDispatch();

  const tableProps = useTable();

  const addDialog = useDialog();
  const { page, rowsPerPage } = tableProps;

  const { count } = usePageCount(getAttributes);

  const [query, setQuery] = useState('');
  useEffect(() => {
    if (query.length !== 0) {
      dispatch(getAttributes({ page, q: query }));
    } else {
      dispatch(getAttributes({ page, limit: rowsPerPage }));
    }
  }, [dispatch, page, query, rowsPerPage]);

  const { data, loading } = useRequestState({
    stateSelector: selectAttributesState,
    errorShown: true,
  });

  const handleSearchChange = (event: any) => {
    setQuery(event.target.value);
  };

  const onConfirmSelect = () => {
    onSubmit?.(tableProps.selected.map((id) => data!.find((item) => item.id === id)!));
  };
  const flattenData = (data: any) => {
    const serializeData = data?.map((elem: any) => {
      return {
        ...elem,
        status: elem.active === true ? 'Active' : 'InActive',
      };
    });
    return serializeData;
  };
  return (
    <>
      <Header
        title={Strings.pageTitle.attributes}
        helperText={''}
        actionButtonTitle={Strings.button.addAttribute}
        onClickActionButton={addDialog.show}
        searchBarProps={{
          placeholder: Strings.common.searchWithNameId,
          onChange: handleSearchChange,
        }}
        data={flattenData(data)}
        csvHead={TABLE_HEAD_CSV}
        csvFileName={'attribute-list.csv'}
        additionalExportButtonTitle={Strings.button.exportCsv}
        additionalExportPdfButtonTitle={Strings.button.exportpdf}
      />

      <AttributeList
        loading={loading}
        tableProps={tableProps}
        rows={data}
        count={count}
        selectedAttributes={selectedAttributes}
      />
      {addDialog.visible && <AddDialog open onClose={addDialog.hide} />}

      {!!onSubmit && (
        <DialogActions>
          <Button variant="outlined" color="inherit" onClick={onClose}>
            {Strings.button.cancel}
          </Button>

          <Button variant="contained" onClick={onConfirmSelect}>
            {Strings.button.save}
          </Button>
        </DialogActions>
      )}
    </>
  );
};

export { AttributeListPage };
