import { Checkbox } from '@mui/material';
import { useDispatch } from 'react-redux';

import { IMAGE_BASE_URL } from '@app/configs';
import { TableCellCustom } from '@app/components/table';
import { CustomAvatar } from '@app/components/custom-avatar';
import { IBanner } from '../types';
import { updateBannerStatus } from '../slice';
import { TruncatedTypography } from '@app/components/typography';
import { useRequestState } from '@app/hooks';
import { selectUpdateBannerState } from '../selectors';
import { RowActions } from './row-actions';

interface Props {
  row: IBanner;
  selected: boolean;
  onSelectRow?: VoidFunction;
}

const BannerRow = (props: Props) => {
  const { row, onSelectRow } = props;
  const dispatch = useDispatch();

  const handleUpdateBannerStatus = (id: any) => {
    dispatch(updateBannerStatus(id));
  };

  useRequestState({
    stateSelector: selectUpdateBannerState,
    successMessageShown: true,
    errorShown: true,
  });

  return (
    <>
      <TableCellCustom onClick={onSelectRow} align="left">
        <TruncatedTypography text={row.id ?? 'NA'} height={20} lines={1} />
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow}>
        <CustomAvatar name={row.name} color="primary" alt="pic" src={IMAGE_BASE_URL + row.image} />
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <TruncatedTypography text={row.name ?? 'NA'} height={20} lines={1} />
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <TruncatedTypography text={row.description ?? 'NA'} height={20} lines={1} />
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <TruncatedTypography text={row.bannerType ?? 'NA'} height={20} lines={1} />
      </TableCellCustom>
      <TableCellCustom align="center" width={50}>
        <Checkbox
          defaultChecked={row.isActive}
          onChange={() => {
            handleUpdateBannerStatus(row.id);
          }}
        />
      </TableCellCustom>
      <TableCellCustom align="right" width={50}>
        <RowActions row={row} />
      </TableCellCustom>
    </>
  );
};

export { BannerRow };
