import { Checkbox, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';

import { IMAGE_BASE_URL } from '@app/configs';
import { TableCellCustom } from '@app/components/table';
import { CustomAvatar } from '@app/components/custom-avatar';
import { ICollection } from '../types';
import { updateCollectionStatus } from '../slice';
import { TruncatedTypography } from '@app/components/typography';
import { selectUpdateCollectionStatus } from '../selectors';
import { useRequestState } from '@app/hooks';
import { RowActions } from './row-actions';

interface Props {
  row: ICollection;
  selected: boolean;
  onSelectRow: VoidFunction;
}

const CollectionRow = (props: Props) => {
  const { row, onSelectRow } = props;
  const dispatch = useDispatch();

  useRequestState({
    stateSelector: selectUpdateCollectionStatus,
    successMessageShown: true,
    errorShown: true,
  });

  const handleUpdateCollectionStatus = (id: any) => {
    dispatch(updateCollectionStatus(id));
  };
  return (
    <>
      <TableCellCustom onClick={onSelectRow} align="left">
        <Typography variant="body2">{row?.id ?? 'NA'}</Typography>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow}>
        <CustomAvatar name={row?.name} color="primary" alt="pic" src={IMAGE_BASE_URL + row.image} />
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <Typography variant="body2">{row?.name ?? 'NA'}</Typography>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <Typography variant="body2">{row?.skuIds ?? 'NA'}</Typography>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <TruncatedTypography text={row?.description ?? 'NA'} height={20} lines={1} />
      </TableCellCustom>
      <TableCellCustom align="center" width={50}>
        <Checkbox
          defaultChecked={row.active}
          onChange={() => {
            handleUpdateCollectionStatus(row.id);
          }}
        />
      </TableCellCustom>
      <TableCellCustom align="right" width={50}>
        <RowActions row={row} />
      </TableCellCustom>
    </>
  );
};

export { CollectionRow };
