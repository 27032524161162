import { useCallback, useEffect, useState } from 'react';
import {
  Divider,
  Stack,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  Box,
  IconButton,
  MenuItem,
  useTheme,
  Radio,
  Select,
  Typography,
  FormControl,
  InputLabel,
  Checkbox,
  Tooltip,
  FormControlLabel,
} from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { debounce, get } from 'lodash';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import dayjs from 'dayjs';

import {
  Iconify,
  FormProvider,
  RHFTextField,
  RHFUploadAvatar,
  RHFAutocomplete,
} from '@app/components';
import { Strings } from '@app/constants';
import { Header } from '@app/sections/header';
import { useDialog, useRequestState } from '@app/hooks';
import { getProducts, selectProductsState } from '@app/modules/products';
import { IAttribute } from '@app/modules/attribute';
import { Product } from '@app/components/detail-card/types';
import { useTable } from '@app/components/table';
import { IProduct } from '@app/modules/products/types';
import { selectAddSkuState } from '../selectors';
import { IAddSkuSchema, ISku } from '../types';
import { addSkuSchema } from '../validator';
import { addSku } from '../slice';
import SelectAttributeDialog from './select-attribute-dialog';
import { RowActions } from './row-actions';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  row?: ISku;
  id?: string;
  name?: string;
}

const AddSku = ({ open, onClose, row }: Props) => {
  const dispatch = useDispatch();
  const attributeDialog = useDialog();
  const [selectedAttributes, setSelectedAttributes] = useState<IAttribute[]>([]);
  const [imageErrOpen, setImageErrOpen] = useState(false);
  const [noImage, setNoImage] = useState(false);
  const [checkedAttributes, setCheckedAttributes] = useState<Record<number, boolean>>({});

  const handleCheckboxChange = (attrId: number, isChecked: boolean) => {
    setCheckedAttributes((prev) => ({
      ...prev,
      [attrId]: isChecked,
    }));
  };

  const handleAttribute = () => {
    const attrs: IAttribute[] =
      row?.skuAttributes?.map((skuAtt) => ({
        ...skuAtt.attribute,
        attributeValue: skuAtt.attributeValue,
      })) ?? [];

    setSelectedAttributes(attrs);

    onConfirmAttributeSelection(attrs);
  };

  const methods = useForm<IAddSkuSchema>({
    resolver: yupResolver(addSkuSchema(selectedAttributes)) as any,
    defaultValues: {
      name: get(row, 'name', ''),
      productId: get(row?.products, 'name', ''),
      productImage: row?.images?.length ? row.images : '',
      desc: get(row, 'desc', ''),
      attributes: selectedAttributes.reduce((acc: Record<string, string>, attr) => {
        acc[`att_${attr.id}`] = '';
        return acc;
      }, {}),
      variantDescription: row?.skuAttributes?.[0]?.showAsDescription
        ? 'showAsDescription'
        : row?.skuAttributes?.[0]?.showAsVariant
          ? 'showAsVariant'
          : '',
    },
  });

  useEffect(() => {
    methods.reset({
      name: get(row, 'name', ''),
      productId: get(row?.products, 'name', ''),
      productImage: row?.images?.length ? row.images : '',
      desc: get(row, 'desc', ''),
      attributes: {},
      variantDescription: row?.skuAttributes?.[0]?.showAsDescription
        ? 'showAsDescription'
        : row?.skuAttributes?.[0]?.showAsVariant
          ? 'showAsVariant'
          : '',
    });
    handleAttribute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row, methods, open]);

  const onConfirmAttributeSelection = (attributes: any) => {
    if (attributes?.length > 0) {
      attributes.forEach((attr: any) => {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        methods.setValue(`attributes.att_${attr.id}`, attr.attributeValue);
      });
    } else {
      methods.setValue('attributes', {});
    }
    setSelectedAttributes(attributes);
  };

  const tableProps = useTable();
  const page = tableProps.page;
  const theme = useTheme();

  const [query, setQuery] = useState('');

  const handleProductChange = (event: React.ChangeEvent<any>, value: any | null) => {
    methods.setValue('productId', value);
    if (value) {
      const selectedProduct = productsState?.find((product: any) => product.id === value.id);
      if (selectedProduct) {
        methods.setValue('desc', selectedProduct?.description);
      }
    }
  };

  const handleProductInputChange = debounce((event: React.ChangeEvent<any>, value) => {
    setQuery(value);
  }, 300);

  useEffect(() => {
    handleAttribute();
    if (query.length !== 0) {
      dispatch(getProducts({ page, q: query }));
    } else {
      dispatch(getProducts({ page }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, query]);

  const onSubmit = (form: IAddSkuSchema) => {
    let product: any = methods.getValues('productId');
    if (typeof product !== 'object' || !product.id) {
      const selectedCategory = productsState?.find((item: any) => item.name === product);
      if (selectedCategory) {
        product = {
          id: selectedCategory.id,
          label: selectedCategory.name,
        };
      }
    }

    const productImages = methods.getValues('productImage');
    if (productImages.length === 0) {
      setNoImage(true);
    } else if (
      Array.isArray(productImages) &&
      productImages.length >= 1 &&
      productImages.length <= 5
    ) {
      const skuId = 'SKUID-' + dayjs().format('YYYYMMDDHHmmssSSS');
      dispatch(
        addSku({
          id: row?.id,
          ...form,
          productId: form.productId?.id || '',
          productName: product.label,
          skuId,
          productImage: productImages,
          attributes: form?.attributes,
          attributesObject: selectedAttributes,
          variantDescription: form.variantDescription,
          checkedAttributes,
        })
      );
    } else {
      setImageErrOpen(true);
    }
  };

  const { data: productsState, loading: productStateloading } = useRequestState({
    stateSelector: selectProductsState,
  });

  const { loading } = useRequestState({
    stateSelector: selectAddSkuState,
    onSuccess: onClose,
    successMessageShown: true,
    errorShown: true,
  });

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const newFiles = acceptedFiles.map((file: any, index) => {
        return Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
      });

      const updatedFiles = row?.images ? [...row.images, ...newFiles] : newFiles;

      const previousImage = methods.watch('productImage');
      methods.setValue('productImage', [...(previousImage || []), ...newFiles], {
        shouldValidate: true,
      });

      if (row) {
        row.images = updatedFiles;
      }
    },
    [methods, row]
  );

  useWatch({ control: methods.control, name: 'productImage' });

  const reorder = ({ list, startIndex, endIndex }: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };
  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const reorderedItems = reorder({
      list: selectedAttributes,
      startIndex: result.source.index,
      endIndex: result.destination.index,
    });

    setSelectedAttributes(reorderedItems as IAttribute[]);
  };
  const grid = 8;

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    userSelect: 'none',
    padding: `${grid}*2 0`,
    margin: `0 0 ${grid}px 0`,
    background: isDragging ? 'lightgreen' : 'white',
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver: any) => ({
    background: isDraggingOver ? 'lightblue' : 'white',
    padding: 0,
    width: 550,
  });

  const [products, setProducts] = useState<IProduct[]>([]);
  const [productPage, setProductPage] = useState(2);

  useEffect(() => {
    if (productsState) {
      if (!query) {
        setProducts((prevProducts) => [
          ...prevProducts,
          ...productsState.filter(
            (product) => !prevProducts.some((prevProduct) => prevProduct.id === product.id)
          ),
        ]);
      } else {
        setProducts(productsState);
      }
    }
  }, [productsState, query, setProducts]);

  const handleCallProductApi = async () => {
    const nextPage = productPage + 1;
    setProductPage(nextPage);
    await new Promise<void>((resolve) => {
      dispatch(getProducts({ page: productPage }));
      resolve();
    });
  };

  useEffect(() => {
    if (row?.skuAttributes && row?.skuAttributes?.length > 0) {
      const initialCheckedState = row?.skuAttributes?.reduce((acc: any, attr: any) => {
        acc[attr.attributeId] = attr.showAsVariant;

        return acc;
      }, {});

      setCheckedAttributes(initialCheckedState);
    }
  }, [row]);

  return (
    <>
      <FormProvider methods={methods}>
        <Dialog open={open} fullWidth>
          <Header
            variant="dialog"
            title={row?.id ? Strings.pageTitle.editSku : Strings.button.addSku}
            onClose={onClose}
          />

          <Divider />

          <DialogContent>
            <Stack spacing={2} my={2}>
              <RHFTextField name="name" label={Strings.field.name} fullWidth variant="outlined" />

              <RHFAutocomplete
                name="productId"
                label={Strings.field.product}
                options={((products as Product[]) || []).map((product) => ({
                  label: product.name,
                  id: product.id,
                }))}
                onInputChange={handleProductInputChange}
                onChange={handleProductChange}
                getOptionKey={(option: any) => option.id}
                isOptionEqualToValue={(option: Product, value: Product) => option.id === value.id}
                callApi={handleCallProductApi}
                hasMore={productsState?.length !== 0 && !query}
                loading={productStateloading}
              />

              <>
                <RHFTextField
                  name="desc"
                  required
                  label={Strings.field.description}
                  fullWidth
                  variant="outlined"
                  multiline
                  minRows={2}
                />
                <Box display="flex" marginTop={2} flexWrap={'wrap'} position="relative">
                  {Array.isArray(methods.watch('productImage')) &&
                    methods.watch('productImage').map((file: any, index: number) => (
                      <Box
                        key={index}
                        sx={{
                          position: 'relative',
                          width: 115,
                          height: 115,
                          margin: '5px',
                          borderRadius: '10px',
                        }}
                      >
                        <img
                          src={file?.preview ? file?.preview : file}
                          alt={`Preview ${index}`}
                          style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '10px',
                          }}
                        />
                        <IconButton
                          onClick={() => {
                            methods.setValue(
                              'productImage',
                              methods.watch('productImage').filter((image: any) => image !== file)
                            );
                          }}
                          sx={{
                            position: 'absolute',
                            height: 25,
                            width: 25,
                            top: 0,
                            right: 0,
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            color: '#fff',
                            zIndex: 1,
                            '&:hover': {
                              backgroundColor: 'rgba(0, 0, 0, 0.7)',
                            },
                          }}
                        >
                          <Iconify icon="oi:x" />
                        </IconButton>
                      </Box>
                    ))}
                  <RHFUploadAvatar
                    multiple
                    name={'productImage'}
                    onDrop={handleDrop}
                    sx={{
                      borderRadius: '10px',
                      width: 115,
                      height: 115,
                      border: 'none',
                      m: '5px',
                      background: 'grey',
                      opacity: 0.72,
                    }}
                    placeholderSx={{
                      borderRadius: '10px',
                      width: '100%',
                      height: '100%',
                      border: '5px dashed rgba(0, 0, 0, 0.08)',
                    }}
                  />
                </Box>

                {imageErrOpen && (
                  <Stack style={{ color: 'red' }} textAlign={'center'}>
                    {Strings.validation.fiveImgError}
                  </Stack>
                )}
                {noImage && (
                  <Stack style={{ color: 'red' }} textAlign={'center'}>
                    {Strings.validation.chooseImg}
                  </Stack>
                )}
                <Button variant="contained" onClick={attributeDialog.show}>
                  {Strings.button.selectAttribute}
                </Button>
                {selectedAttributes && selectedAttributes?.length > 0 && (
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="unique">
                      {(provided, snapshot) => (
                        <Box
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                        >
                          {selectedAttributes?.map((attr, index) => {
                            return (
                              <Draggable
                                key={attr?.id?.toString()}
                                draggableId={attr?.id?.toString()}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <Box
                                    sx={{ pt: 2 }}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        gap: 0.3,
                                      }}
                                    >
                                      <RowActions
                                        handleDelete={() => {
                                          setSelectedAttributes(
                                            selectedAttributes?.filter(
                                              (attri) => attri?.id !== attr.id
                                            )
                                          );
                                          const attributes = methods.watch('attributes');
                                          methods.setValue(
                                            'attributes',
                                            Object.fromEntries(
                                              Object.entries(attributes).filter(
                                                ([key]) => key !== `att_${attr.id}`
                                              )
                                            )
                                          );
                                          setCheckedAttributes((prev) => {
                                            const { [attr.id]: _, ...updated } = prev;
                                            return updated;
                                          });
                                        }}
                                      />
                                      {attr?.isPrefix || attr?.showAsFilter ? (
                                        <Controller
                                          name={`attributes.${`att_${attr.id}`}`}
                                          control={methods.control}
                                          render={({ field, fieldState: { error } }) => {
                                            return (
                                              <Box
                                                sx={{
                                                  width: '65%',
                                                  display: 'flex',
                                                  flexDirection: 'column',
                                                }}
                                              >
                                                <FormControl sx={{ minWidth: 120 }} size="small">
                                                  <InputLabel
                                                    id="demo-select-small-label"
                                                    sx={{
                                                      color: theme.palette.grey[500],
                                                      '&.Mui-focused': {
                                                        color: 'black !important',
                                                      },
                                                    }}
                                                  >
                                                    {attr.name}
                                                  </InputLabel>
                                                  <Select
                                                    {...field}
                                                    labelId="demo-select-small-label"
                                                    id="demo-select-small"
                                                    label={attr.name}
                                                    sx={{ width: '100%' }}
                                                    size="small"
                                                    value={field.value ?? ''}
                                                    renderValue={(selected) => {
                                                      return selected ?? '';
                                                    }}
                                                  >
                                                    {attr?.prefixes?.map((showing, index) => (
                                                      <MenuItem value={showing.prefix} key={index}>
                                                        <Box display="flex" alignItems="center">
                                                          <Radio
                                                            checked={field.value === showing.prefix}
                                                            onChange={() => {
                                                              field.onChange(showing.prefix);
                                                              methods.setValue(
                                                                `attributes.att_${attr.id}`,
                                                                showing.prefix
                                                              );
                                                            }}
                                                          />
                                                          {showing?.prefix}
                                                        </Box>
                                                      </MenuItem>
                                                    ))}
                                                  </Select>
                                                </FormControl>
                                                {error && (
                                                  <Typography
                                                    color="error"
                                                    variant="caption"
                                                    sx={{ mt: 0.5 }}
                                                  >
                                                    {error.message}
                                                  </Typography>
                                                )}
                                              </Box>
                                            );
                                          }}
                                        />
                                      ) : (
                                        <RHFTextField
                                          key={attr.id}
                                          name={`attributes.${`att_${attr.id}`}`}
                                          required
                                          size="small"
                                          label={attr.name}
                                          sx={{ width: '65%' }}
                                          multiline
                                          fullWidth
                                          variant="outlined"
                                        />
                                      )}
                                      <Tooltip
                                        title={
                                          attr.isPrefix || attr?.showAsFilter
                                            ? 'This attribute can be used as a Variant because its isPrefix or showAsFilter is set to true'
                                            : 'This attribute cannot be used as a Variant because its isPrefix and showAsFilter is set to false'
                                        }
                                      >
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={checkedAttributes[attr.id] || false}
                                              disabled={!attr.isPrefix && !attr.showAsFilter}
                                              onChange={(e) => {
                                                handleCheckboxChange(attr.id, e.target.checked);
                                              }}
                                              sx={{
                                                color:
                                                  attr.isPrefix || attr.showAsFilter
                                                    ? theme.palette.primary.main
                                                    : theme.palette.grey[500],
                                                ml: 2,
                                              }}
                                            />
                                          }
                                          label="Show As Variant"
                                        />
                                      </Tooltip>
                                    </Box>
                                  </Box>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </Box>
                      )}
                    </Droppable>
                  </DragDropContext>
                )}
              </>
            </Stack>
          </DialogContent>

          <Divider />

          <DialogActions>
            <Button variant="outlined" color="inherit" onClick={onClose}>
              {Strings.button.cancel}
            </Button>

            <LoadingButton
              loading={loading}
              type="submit"
              variant="contained"
              onClick={methods.handleSubmit(onSubmit)}
            >
              {Strings.button.save}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </FormProvider>

      {attributeDialog.visible && (
        <SelectAttributeDialog
          open
          onClose={attributeDialog.hide}
          onSubmit={(selected) => {
            const updatedSelected = selected?.map((sel) => {
              const getData = row?.skuAttributes?.filter(
                (att: any) => att?.attributeId === sel?.id
              );
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              const watchedValue = methods.watch(`attributes.${`att_${sel.id}`}`);
              return {
                ...sel,
                attributeValue:
                  watchedValue?.length > 0 ? watchedValue : getData?.[0]?.attributeValue,
              };
            });
            onConfirmAttributeSelection(updatedSelected);
            attributeDialog.hide();
          }}
          selectedAttributes={selectedAttributes}
        />
      )}
    </>
  );
};

export default AddSku;
