import * as Yup from 'yup';

import { Strings } from '@app/constants';

export const addSkuSchema = (selectedAttributes: Array<{ id: number; name: string }>) =>
  Yup.object().shape({
    productId: Yup.mixed()
      .notOneOf([''], Strings.validation.fieldRequired(Strings.field.product))
      .required(Strings.validation.fieldRequired(Strings.field.product)),
    desc: Yup.string().required(Strings.validation.fieldRequired(Strings.field.description)),
    name: Yup.string()
      .required(Strings.validation.fieldRequired(Strings.field.name))
      .test('no-leading-whitespace', 'Name should not allowed white spaces', (value) =>
        value ? !/^\s/.test(value) : false
      ),
    attributes: Yup.object().shape(
      selectedAttributes.reduce((schema: Record<string, Yup.StringSchema>, attr) => {
        schema[`att_${attr.id}`] = Yup.string()
          .required(`The attribute "${attr.name}" is required.`)
          .test(
            'no-leading-whitespace',
            `"${attr.name}" should not allowed white spaces`,
            (value) => (value ? !/^\s/.test(value) : false)
          );
        return schema;
      }, {})
    ),
  });
