import { useDispatch } from 'react-redux';
import { IconButton } from '@mui/material';

import {
  Iconify,
  MenuPopover,
  RowActions as MenuRowActions,
  useMenuPopover,
} from '@app/components';
import { Strings } from '@app/constants';
import { useDialog, useRequestState } from '@app/hooks';
import { IAttribute } from '../types';
import AddSku from './add-attribute';
import { attributeDelete } from '../slice';
import { selectDeleteAttributeState } from '../selectors';

interface Props {
  row: IAttribute;
  showActions: boolean;
}

const RowActions = ({ row, showActions }: Props) => {
  const dispatch = useDispatch();
  const menuPopover = useMenuPopover();
  const editDialog = useDialog();

  const actions = [
    {
      label: Strings.rowActions.edit,
      callback: editDialog.show,
    },
    {
      label: Strings.rowActions.delete,
      callback: () => {
        dispatch(attributeDelete({ id: row?.id }));
      },
    },
  ];

  useRequestState({
    stateSelector: selectDeleteAttributeState,
    successMessageShown: true,
    errorShown: true,
  });

  return (
    <>
      <IconButton onClick={menuPopover.show} disabled={!showActions}>
        <Iconify icon="eva:more-vertical-fill" />
      </IconButton>
      <MenuPopover
        open={menuPopover.rowMenuAnchorElement}
        arrow="right-top"
        sx={{ width: 180 }}
        onClose={menuPopover.hide}
      >
        <MenuRowActions actions={actions} onClickAction={menuPopover.hide} />
      </MenuPopover>

      {editDialog.visible && <AddSku open onClose={editDialog.hide} row={row} />}
    </>
  );
};

export { RowActions };
