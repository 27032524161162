import { useNavigate } from 'react-router-dom';

import { PaginatedTable } from '@app/components/paginated-table';
import { TableProps } from '@app/components/table';
import { IBanner } from '../types';
import { BannerRow } from './banner-row';
import { PATH_DASHBOARD } from '@app/routes/paths';

const TABLE_HEAD = [
  { id: 'id', label: 'Id', align: 'left', sortable: false },
  { id: 'image', label: 'Image', align: 'left', sortable: false },
  { id: 'name', label: 'Name', align: 'center', sortable: false },
  { id: 'description', label: 'Description', align: 'center', sortable: false },
  { id: 'type', label: 'Type', align: 'center', sortable: false },
  { id: 'status', label: 'Active/InActive', align: 'left', sortable: false },
  { id: 'action', label: 'Action', align: 'left', sortable: false },
];

interface Props {
  tableProps: TableProps;
  rows?: IBanner[];
  loading: boolean;
  count?: number;
}

const BannerList = ({ tableProps, rows, loading, count }: Props) => {
  const navigation = useNavigate();

  const handleSelect = (data: any) => {
    navigation(PATH_DASHBOARD.bannerDetail, { state: { data } });
  };

  return (
    <PaginatedTable<IBanner>
      head={TABLE_HEAD}
      tableProps={tableProps}
      data={rows}
      loading={loading}
      isServerSidePaging={true}
      totalCount={count}
    >
      {(row) => (
        <BannerRow
          row={row}
          selected={tableProps.selected.includes(row.id)}
          onSelectRow={() => {
            handleSelect(row);
          }}
        />
      )}
    </PaginatedTable>
  );
};
export default BannerList;
