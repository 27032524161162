import { useCallback, useState } from 'react';
import {
  Divider,
  Stack,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  MenuItem,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { get } from 'lodash';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux';

import { Strings } from '@app/constants';
import { Header } from '@app/sections/header';
import { FormProvider, RHFTextField, RHFUploadAvatar } from '@app/components';
import { IAddBannerSchema, IBanner } from '../types';
import { addBannerSchema } from '../validator';
import { IMAGE_BASE_URL } from '@app/configs';
import { addBanner } from '../slice';
import { selectAddBannerState } from '../selectors';
import { useRequestState } from '@app/hooks';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  row?: IBanner;
}

const AddBanner = ({ open, onClose, row }: Props) => {
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const dispatch = useDispatch();

  const methods = useForm<IAddBannerSchema>({
    resolver: yupResolver(addBannerSchema) as any,
    defaultValues: {
      name: get(row, 'name', ''),
      image: row?.image ? IMAGE_BASE_URL + row.image : '',
      bannerType: get(row, 'bannerType', ''),
      buttonTitle: get(row, 'buttonTitle', ''),
      description: get(row, 'description', ''),
    },
  });

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        methods.setValue('image', newFile, { shouldValidate: true });
        setIsImageUploaded(true);
      }
    },
    [methods]
  );

  const onSubmit = (form: IAddBannerSchema) => {
    const id = row?.id ?? 0;
    dispatch(
      addBanner({
        ...form,
        image: methods.getValues('image'),
        id,
      })
    );
  };
  const { loading } = useRequestState({
    stateSelector: selectAddBannerState,
    onSuccess: onClose,
    successMessageShown: true,
    errorShown: true,
  });

  return (
    <>
      <FormProvider methods={methods}>
        <Dialog open={open} fullWidth>
          <Header variant="dialog" title={Strings.button.addBanner} onClose={onClose} />

          <Divider />

          <DialogContent>
            <Stack spacing={2} my={2}>
              <RHFTextField
                name="name"
                required
                label={Strings.field.name}
                fullWidth
                variant="outlined"
              />
              <RHFTextField
                name="buttonTitle"
                label={Strings.field.buttonTitle}
                fullWidth
                variant="outlined"
              />
              <RHFTextField name="isActive" label={Strings.field.active} variant="outlined" select>
                <MenuItem value={'active'}>Active</MenuItem>
                <MenuItem value={'inActive'}>InActive</MenuItem>
              </RHFTextField>

              <RHFTextField
                name="bannerType"
                required
                label={Strings.field.type}
                variant="outlined"
                select
              >
                <MenuItem value={'topBanner'}>Top Banner</MenuItem>
                <MenuItem value={'middleBanner'}>Middle Banner</MenuItem>
                <MenuItem value={'lowerBanner'}>Lower Banner</MenuItem>
              </RHFTextField>

              <RHFTextField
                name="description"
                required
                label={Strings.field.description}
                fullWidth
                variant="outlined"
                multiline
                minRows={2}
              />

              <Stack alignItems={'start'}>
                <RHFUploadAvatar
                  name={'image'}
                  onDrop={handleDrop}
                  sx={{
                    borderRadius: '10px',
                    width: 115,
                    height: 115,
                    border: 'none',
                  }}
                  placeholderSx={{
                    borderRadius: '10px',
                    width: '100%',
                    height: '100%',
                    border:
                      isImageUploaded || row?.image ? 'none' : '5px dashed rgba(0, 0, 0, 0.08)',
                  }}
                />
              </Stack>
            </Stack>
          </DialogContent>

          <Divider />

          <DialogActions>
            <Button variant="outlined" color="inherit" onClick={onClose}>
              {Strings.button.cancel}
            </Button>

            <LoadingButton
              loading={loading}
              type="submit"
              variant="contained"
              onClick={methods.handleSubmit(onSubmit)}
            >
              {Strings.button.save}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
};

export default AddBanner;
