import { Stack, Card, Typography } from '@mui/material';

import { Strings } from '@app/constants';
import { ICustomerAddress } from '../types';
interface Props {
  customer?: ICustomerAddress;
}

const CustomerDetailCard = ({ customer }: Props) => {
  return (
    <Card sx={{ padding: '2% 2% 5% 2%', width: '50%', borderRadius: '5px' }}>
      <Typography variant="h6">{Strings.detailCard.customerDetails}</Typography>
      <Stack flexDirection="row" justifyContent="space-between">
        <Stack mt={5} gap={1.5}>
          <Typography sx={{ fontSize: '14px' }}>{Strings.detailCard.name}</Typography>
          <Typography sx={{ fontSize: '14px' }}>{Strings.detailCard.phone}</Typography>
          <Typography sx={{ fontSize: '14px' }}>{Strings.detailCard.addressType}</Typography>
          <Typography sx={{ fontSize: '14px' }}>{Strings.detailCard.address}</Typography>
          <Typography sx={{ fontSize: '14px' }}>{Strings.detailCard.city}</Typography>
          <Typography sx={{ fontSize: '14px' }}>{Strings.detailCard.country}</Typography>
        </Stack>
        <Stack mt={5} ml={4} gap={1.5}>
          <Typography sx={{ fontSize: '14px' }}>{customer?.name ?? 'NA'}</Typography>
          <Typography sx={{ fontSize: '14px' }}>
            {customer?.mobileNumber
              ? `${customer?.countryCode ?? ''}${customer?.mobileNumber}`
              : 'NA'}
          </Typography>
          <Typography sx={{ fontSize: '14px' }}>{customer?.addressType ?? 'NA'}</Typography>
          <Typography
            sx={{
              fontSize: '14px',
              marginTop: '10px',
            }}
          >
            {customer?.address ?? 'NA'}
          </Typography>
          <Typography sx={{ fontSize: '14px' }}>{customer?.city ?? 'NA'}</Typography>
          <Typography sx={{ fontSize: '14px' }}>{customer?.country ?? 'NA'}</Typography>
        </Stack>
      </Stack>
    </Card>
  );
};
export { CustomerDetailCard };
