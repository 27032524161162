import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { IconButton } from '@mui/material';

import { DeleteDialog } from '@app/components/dialog';
import {
  Iconify,
  MenuPopover,
  RowActions as MenuRowActions,
  useMenuPopover,
} from '@app/components';
import { Strings } from '@app/constants';
import { useDialog, useRequestState } from '@app/hooks';
import { selectDeleteCollectionState } from '../selectors';
import { deleteCollection } from '../slice';

interface Props {
  row: any;
}

const RowActions = ({ row }: Props) => {
  const menuPopover = useMenuPopover();
  const deleteDialog = useDialog();
  const dispatch = useDispatch();

  const actions = [
    {
      label: Strings.rowActions.delete,
      callback: deleteDialog.show,
    },
  ];

  const handleClosePopup = useCallback(() => {
    deleteDialog.hide();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { loading } = useRequestState({
    stateSelector: selectDeleteCollectionState,
    successMessageShown: true,
    onSuccess: handleClosePopup,
    errorShown: true,
  });

  return (
    <>
      <IconButton onClick={menuPopover.show}>
        <Iconify icon="eva:more-vertical-fill" />
      </IconButton>

      <MenuPopover
        open={menuPopover.rowMenuAnchorElement}
        arrow="right-top"
        sx={{ width: 180 }}
        onClose={menuPopover.hide}
      >
        <MenuRowActions actions={actions} onClickAction={menuPopover.hide} />
      </MenuPopover>

      <DeleteDialog
        title={`${Strings.rowActions.delete} ${Strings.header.collection}?`}
        caption={Strings.common.deleteCaption(Strings.header.collection)}
        deleteButtonTitle={Strings.button.confirm}
        loading={loading}
        onClose={deleteDialog.hide}
        visible={deleteDialog.visible}
        onClickDelete={() => {
          dispatch(deleteCollection({ id: row.id }));
        }}
      />
    </>
  );
};

export { RowActions };
