import { Divider, Stack, DialogContent, DialogActions, Dialog, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { get } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { Strings } from '@app/constants';
import { useRequestState } from '@app/hooks';
import { Header } from '@app/sections/header';
import { FormProvider, RHFTextField, RHFUploadAvatar } from '@app/components';
import { IAddAdminUserSchema, IUser } from '../types';
import { addAdminSchema } from '../validator';
import { selectAddAdminState } from '../selectors';
import { addAdmin } from '../slice';
import { IMAGE_BASE_URL } from '@app/configs';
interface Props {
  open: boolean;
  onClose: VoidFunction;
  row?: IUser;
}

const AddAdmin = ({ open, onClose, row }: Props) => {
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const dispatch = useDispatch();
  const resolver = row ? () => ({ values: {}, errors: {} }) : yupResolver(addAdminSchema);
  const methods = useForm<IAddAdminUserSchema>({
    resolver: resolver as any,
    defaultValues: {
      name: get(row, 'firstName', ''),
      image: row?.profileImage ? IMAGE_BASE_URL + row?.profileImage : '',
    },
  });

  useEffect(() => {
    methods.reset({
      name: get(row, 'firstName', ''),
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      image: row?.profileImage ? IMAGE_BASE_URL + row?.profileImage : '',
    });
  }, [row, methods]);

  const { loading } = useRequestState({
    stateSelector: selectAddAdminState,
    errorShown: true,
    successMessageShown: true,
    onSuccess: onClose,
  });

  const onSubmit = (form: IAddAdminUserSchema) => {
    const id = row?.id ?? 0;
    dispatch(
      addAdmin({ ...form, name: methods.getValues('name'), image: methods.getValues('image'), id })
    );
  };

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });
      if (file) {
        methods.setValue('image', newFile, { shouldValidate: true });
        setIsImageUploaded(true);
      }
    },
    [methods]
  );
  return (
    <>
      {
        <FormProvider methods={methods}>
          <Box sx={{ flexGrow: 1 }}>
            <Dialog open={open} fullWidth>
              <Header
                variant="dialog"
                title={row ? Strings.header.editAdmin : Strings.header.addAdmin}
                onClose={onClose}
              />
              <Divider />
              <DialogContent>
                <Stack spacing={2} my={2}>
                  <RHFTextField
                    name="name"
                    required
                    label={Strings.field.name}
                    fullWidth
                    variant="outlined"
                  />
                  {!row && (
                    <>
                      <Grid item xs={12} style={{ marginBottom: 0 }}>
                        <RHFTextField
                          name="email"
                          required
                          label={Strings.field.email}
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} style={{ marginBottom: 0 }}>
                        <RHFTextField
                          name="mobileNumber"
                          label={Strings.field.mobile}
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} style={{ marginBottom: 0 }}>
                        <RHFTextField
                          name="password"
                          required
                          label={Strings.field.password}
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                    </>
                  )}
                  <RHFUploadAvatar
                    name={'image'}
                    onDrop={handleDrop}
                    sx={{
                      borderRadius: '10px',
                      width: 115,
                      height: 115,
                      border: 'none',
                    }}
                    placeholderSx={{
                      borderRadius: '10px',
                      width: '100%',
                      height: '100%',
                      border: isImageUploaded ? 'none' : '5px dashed rgba(0, 0, 0, 0.08)',
                    }}
                  />
                </Stack>
              </DialogContent>
              <Divider />
              <DialogActions>
                <Button variant="outlined" color="inherit" onClick={onClose}>
                  {Strings.button.cancel}
                </Button>
                <LoadingButton
                  loading={loading}
                  type="submit"
                  variant="contained"
                  onClick={methods.handleSubmit(onSubmit)}
                >
                  {Strings.button.save}
                </LoadingButton>
              </DialogActions>
            </Dialog>
          </Box>
        </FormProvider>
      }
    </>
  );
};

export default AddAdmin;
