import { Divider, Stack, DialogContent, DialogActions, Dialog, Button } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import { debounce, get } from 'lodash';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useCallback, useState, useEffect } from 'react';
import dayjs from 'dayjs';

import { FormProvider, RHFAutocomplete, RHFTextField, RHFUploadAvatar } from '@app/components';
import { useRequestState } from '@app/hooks';
import { IAddMasterSchema, IUser } from '../types';
import { addMasterSchema } from '../validator';
import { Strings } from '@app/constants';
import { Header } from '@app/sections/header';
import { RHFTimePicker } from '@app/components/hook-form/RHFTimePicker';
import { addMaster, getAddress } from '../slice';
import { selectAddMasterState, selectAddressState } from '../selectors';
import { IMAGE_BASE_URL } from '@app/configs';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  row?: IUser;
}

const AddMaster = ({ open, onClose, row }: Props) => {
  const dispatch = useDispatch();

  const methods = useForm<IAddMasterSchema>({
    resolver: yupResolver(addMasterSchema) as any,
    defaultValues: {
      name: get(row, 'firstNamname', ''),
      mobileNumber: get(row?.addresses?.[0], 'mobileNumber', ''),
      description: get(row?.addresses?.[0], 'description', ''),
      address: get(row?.addresses?.[0], 'address', ''),
      city: get(row?.addresses?.[0], 'city', ''),
      country: get(row?.addresses?.[0], 'country', ''),
      coverageArea: get(row, 'coverageArea', ''),
      latitude: get(row?.addresses?.[0]?.location, 'lat', ''),
      longitude: get(row?.addresses?.[0]?.location, 'long', ''),
      email: get(row, 'email', ''),
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      startTime: row ? dayjs(`2022-01-01 ${get(row, 'startTime', '00:00')}`) : null,
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      endTime: row ? dayjs(`2022-01-01 ${get(row, 'endTime', '00:00')}`) : null,
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      image: row?.profileImage ? IMAGE_BASE_URL + row?.profileImage : '',
      searchAddress: get(row?.addresses?.[0], 'city', ''),
    },
  });

  useEffect(() => {
    methods.reset({
      name: get(row, 'firstNamname', ''),
      mobileNumber: get(row?.addresses?.[0], 'mobileNumber', ''),
      description: get(row?.addresses?.[0], 'description', ''),
      address: get(row?.addresses?.[0], 'address', ''),
      city: get(row?.addresses?.[0], 'city', ''),
      country: get(row?.addresses?.[0], 'country', ''),
      coverageArea: get(row, 'coverageArea', ''),
      latitude: get(row?.addresses?.[0]?.location, 'lat', ''),
      longitude: get(row?.addresses?.[0]?.location, 'long', ''),
      email: get(row, 'email', ''),
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      startTime: row ? dayjs(`2022-01-01 ${get(row, 'startTime', '00:00')}`) : null,
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      endTime: row ? dayjs(`2022-01-01 ${get(row, 'endTime', '00:00')}`) : null,
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      image: row?.profileImage ? IMAGE_BASE_URL + row?.profileImage : '',
      searchAddress: get(row?.addresses?.[0], 'city', ''),
    });
  }, [row, methods]);

  const { loading } = useRequestState({
    stateSelector: selectAddMasterState,
    errorShown: true,
    successMessageShown: true,
    onSuccess: onClose,
  });

  const onSubmit = (form: IAddMasterSchema) => {
    const id = row?.id ?? 0;
    dispatch(addMaster({ ...form, id }));
  };
  const [cities, setCities] = useState([]);
  const [cities2, setCities2] = useState([]);
  const [query, setQuery] = useState('');

  const { data: address, loading: addressLoader } = useRequestState({
    stateSelector: selectAddressState,
    errorShown: true,
  });

  useEffect(() => {
    if (address && address.length > 0) {
      setCities(address?.map((row: any) => row.title) || []);
      setCities2(address || []);
    }
  }, [address]);

  useEffect(() => {
    if (query.length > 0) {
      dispatch(getAddress({ q: query }));
    }
  }, [query, dispatch]);

  const handleInputChange = debounce((event: any, value: string) => {
    if (event && event.type === 'change') {
      setQuery(event.target.value);
    } else {
      void onSelect(value);
    }
  }, 670);

  const onSelect = async (value: string) => {
    // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
    const addressSelected: any = cities2.find((row: any) => row?.title === value);

    if (addressSelected) {
      methods.setValue('description', addressSelected.Description);
      methods.setValue('address', addressSelected.title);
      methods.setValue('city', addressSelected.address.city);
      methods.setValue('country', addressSelected.address.countryName);
      methods.setValue('pinCode', addressSelected.address.postalCode);
      methods.setValue('latitude', addressSelected.position.lat.toString());
      methods.setValue('longitude', addressSelected.position.lng.toString());
      methods.setValue('coverageArea', addressSelected.coverageArea);
    }
  };
  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });
      if (file) {
        methods.setValue('image', newFile, { shouldValidate: true });
      }
    },
    [methods]
  );
  return (
    <>
      <FormProvider methods={methods}>
        <Box sx={{ flexGrow: 1 }}>
          <Dialog open={open} fullWidth>
            <Header
              variant="dialog"
              title={row ? Strings.header.editMaster : Strings.header.addMaster}
              onClose={onClose}
            />
            <Divider />
            <DialogContent>
              <Stack spacing={2} my={2}>
                <RHFTextField
                  name="name"
                  required
                  label={Strings.field.name}
                  fullWidth
                  variant="outlined"
                />
                <Grid item xs={12} style={{ marginBottom: 0 }}>
                  <RHFTextField
                    name="email"
                    required
                    label={Strings.field.email}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 0 }}>
                  <RHFTextField
                    name="mobileNumber"
                    required
                    label={Strings.field.mobile}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid container spacing={1} style={{ marginTop: 5, marginBottom: 5 }}>
                  <Grid item xs={6} sx={{ marginLeft: '-9px' }}>
                    <RHFTimePicker name="startTime" label={Strings.field.startTime} />
                  </Grid>
                  <Grid item xs={6}>
                    <RHFTimePicker name="endTime" label={Strings.field.endTime} />
                  </Grid>
                </Grid>

                <Grid item xs={12} spacing={2} style={{ marginTop: 7 }}>
                  <RHFAutocomplete
                    name="searchAddress"
                    label={Strings.field.searchAddress}
                    noOptionsText={Strings.field.noCities}
                    options={cities}
                    getOptionKey={(option: any) => option}
                    isOptionEqualToValue={(option: any, value: any) => option === value}
                    onInputChange={handleInputChange}
                    loading={addressLoader}
                  />
                </Grid>
                <Grid container spacing={1} style={{ marginTop: 5, marginBottom: 5 }}>
                  <Grid item xs={6} sx={{ marginLeft: '-8px' }}>
                    <RHFTextField
                      disabled
                      name="latitude"
                      label={Strings.field.latitude}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <RHFTextField
                      disabled
                      name="longitude"
                      label={Strings.field.longitude}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={1} style={{ marginTop: 0, marginBottom: 5 }}>
                  <Grid item xs={6} sx={{ marginLeft: '-9px' }}>
                    <RHFTextField
                      name="coverageArea"
                      required
                      label={Strings.field.coverageArea}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <RHFTextField
                      name="address"
                      required
                      label={Strings.field.address}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} style={{ marginTop: 0, marginBottom: 5 }}>
                  <Grid item xs={6} sx={{ marginLeft: '-9px' }}>
                    <RHFTextField
                      name="city"
                      required
                      label={Strings.field.city}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6} spacing={2}>
                    <RHFTextField
                      name="country"
                      required
                      label={Strings.field.country}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} style={{ marginTop: 0, marginBottom: 5 }}>
                  <Grid item xs={12} sx={{ marginLeft: '-9px' }}>
                    <RHFTextField
                      name="description"
                      label={Strings.field.description}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} style={{ marginTop: 0, marginBottom: 5 }}>
                  <Grid item xs={12} sx={{ marginLeft: '-9px' }}>
                    <Box display="flex" marginTop={2} flexWrap={'wrap'}>
                      {Array.isArray(methods.getValues('image')) &&
                        methods.getValues('image').map((file: any, index: number) => (
                          <img
                            key={index}
                            src={row ? file : file.preview}
                            alt={`Preview ${index}`}
                            style={{
                              width: 115,
                              height: 115,
                              margin: '5px',
                              borderRadius: '10px',
                            }}
                          />
                        ))}
                      <RHFUploadAvatar
                        multiple
                        name={'image'}
                        onDrop={handleDrop}
                        sx={{
                          borderRadius: '10px',
                          width: 115,
                          height: 115,
                          border: 'none',
                          m: '5px',
                          background: 'grey',
                          opacity: 0.72,
                        }}
                        placeholderSx={{
                          borderRadius: '10px',
                          width: '100%',
                          height: '100%',
                          border: '5px dashed rgba(0, 0, 0, 0.08)',
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Stack>
            </DialogContent>
            <Divider />
            <DialogActions>
              <Button variant="outlined" color="inherit" onClick={onClose}>
                {Strings.button.cancel}
              </Button>
              <LoadingButton
                loading={loading}
                type="submit"
                variant="contained"
                onClick={methods.handleSubmit(onSubmit)}
              >
                {Strings.button.save}
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </Box>
      </FormProvider>
    </>
  );
};

export default AddMaster;
