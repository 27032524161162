import * as Yup from 'yup';

import { Strings } from '@app/constants';

// ---------------addBanerSchema-------------
export const addBannerSchema = Yup.object()
  .shape({
    name: Yup.string()
      .required(Strings.validation.fieldRequired(Strings.field.name))
      .test('no-leading-whitespace', 'Name should not allowed white spaces', (value) =>
        value ? !/^\s/.test(value) : false
      ),
    description: Yup.string()
      .required(Strings.validation.fieldRequired(Strings.field.description))
      .test('no-leading-whitespace', 'Description should not allowed white spaces', (value) =>
        value ? !/^\s/.test(value) : false
      ),
    image: Yup.string().required(Strings.validation.uploadRequired(Strings.field.image)),
    bannerType: Yup.string().required(Strings.validation.selectRequired(Strings.field.type)),
  })
  .required();
