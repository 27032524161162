import * as Yup from 'yup';

import { Strings } from '@app/constants';

export const addAttributeSchema = Yup.object()
  .shape({
    name: Yup.string()
      .required(Strings.validation.fieldRequired(Strings.field.name))
      .test('no-leading-whitespace', 'Name should not allowed white spaces', (value) =>
        value ? !/^\s/.test(value) : false
      ),
    attributes: Yup.array().of(
      Yup.object().shape({
        prefix: Yup.string()
          .required(Strings.validation.fieldRequired(Strings.field.value))
          .test('no-leading-whitespace', 'Value should not allowed white spaces', (value) =>
            value ? !/^\s/.test(value) : false
          ),
      })
    ),
  })
  .required();
